// @ts-nocheck
import React, {useEffect, useState} from "react";
import {Col, Row} from 'reactstrap';

export const DynamicImageTable: React.FC<never> = (props) => {

  if (process.browser) {
    const [data, setData] = useState<any[]>([]);

    useEffect(() => {
      const fetchData = async () => {
        try {
          const response = await fetch('https://pages.spielgruppezuerich.ch/api/imagepairs');
          const jsonData = await response.json();
          setData(jsonData);
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      };

      fetchData();
    }, []);

    return (
      <Row style={{ paddingBottom: '24px' }}>
       {data.map((item) => (
          <>
            <Col xs={12} sm={6} md={3} lg={2} style={{ paddingBottom: '15px' }}>
              <img src={item.thumbnail} alt={item.title} loading="lazy" style={{ maxWidth: '100%', width: '300px' }} />
            </Col>
            <Col xs={12} sm={6} md={3} lg={2}>
              <h2 style={{ fontSize: '1.5em' }}>{item.title}</h2>
              <p style={{ fontSize: '1em' }}>{item.text}</p>
            </Col>
          </>
        ))}
      </Row>
    );
  }

  return <></>
};